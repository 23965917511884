import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import Form from 'components/enForm'
import Meta from 'components/meta'
import Layout from 'components/layout'

import { useJsonForm } from 'gatsby-tinacms-json'

let lang = 'de'

const EnContactIndex = ({ location, data }) => {
  const [node] = useJsonForm(data.dataJson, ContactPageForm)
  const page = node.pages.contact.de

  const config = {
    api: `http://roessler-lee.com/api/contact/index.php`,
    title: page.title,
    buttonValue: page.fields.button,
    successMessage: page.messages.successMessage,
    errorMessage: page.messages.errorMessage,
    msgErrorMessage: page.messages.msgErrorMessage,
    mailErrorMessage: page.messages.mailErrorMessage,
    fields: {
      email: '',
      msg: '',
    },
    fieldsConfig: [
      {
        id: 1,
        label: page.fields.mail.label,
        fieldName: 'email',
        type: 'email',
        placeholder: page.fields.mail.placeholder,
        isRequired: true,
        klassName: 'form-control',
        dataError: 'Valid email is required.',
      },
      {
        id: 2,
        label: page.fields.message.label,
        fieldName: 'msg',
        type: 'textarea',
        placeholder: page.fields.message.placeholder,
        isRequired: true,
        klassName: 'form-control',
        dataError: 'Please, leave us a message.',
      },
    ],
  }

  return (
    <Layout location={location} contact={true}>
      <Helmet>
        <title>{page.site_title} - Reinhold Rößler</title>
      </Helmet>
      <div id="pageTop" className="contact-body" style={{ paddingTop: `7rem` }}>
        <section>
          <div className="container">
            <h1>{page.title}</h1>
            <Form config={config} />
          </div>
        </section>
      </div>
      <section style={{ backgroundColor: `#35424B`, marginTop: `auto` }}>
        <div className="footer container" style={{ marginBottom: `3rem` }}>
          <p style={{ color: `white`, textAlign: `center` }}>
            {page.contact.name}, {page.contact.address} <br />
            {page.contact.telephone} | Email:{' '}
            <a
              style={{ color: `white`, textDecoration: `underline` }}
              href={`mailto:${page.contact.mail}`}
            >
              {page.contact.mail}
            </a>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default EnContactIndex

const ContactPageForm = {
  fields: [
    {
      label: 'Site Title',
      name: `rawJson.pages.contact.${lang}.site_title`,
      component: 'text',
      required: true,
    },
    {
      label: 'Title',
      name: `rawJson.pages.contact.${lang}.title`,
      component: 'text',
      required: true,
    },
    {
      label: 'Form Fields',
      name: `rawJson.pages.contact.${lang}.fields`,
      description: 'Form Fields',
      component: 'group',
      fields: [
        {
          name: 'mail.label',
          component: 'text',
          label: 'Mail Label',
          required: true,
        },
        {
          name: 'mail.placeholder',
          component: 'text',
          label: 'Mail Placeholder',
          required: true,
        },
        {
          name: 'message.label',
          component: 'text',
          label: 'Message Label',
          required: true,
        },
        {
          name: 'message.placeholder',
          component: 'text',
          label: 'Message Placeholder',
          required: true,
        },
      ],
    },
    {
      label: 'Error/Success Messages',
      name: `rawJson.pages.contact.${lang}.messages`,
      description: 'Error/Success Messages',
      component: 'group',
      fields: [
        {
          name: 'successMessage',
          component: 'text',
          label: 'Success Message',
          required: true,
        },
        {
          name: 'errorMessage',
          component: 'text',
          label: 'Error Message',
          required: true,
        },
        {
          name: 'msgErrorMessage',
          component: 'text',
          label: 'Invalid Message Error',
          required: true,
        },
        {
          name: 'mailErrorMessage',
          component: 'text',
          label: 'Invalid Mail Error',
          required: true,
        },
      ],
    },
    {
      name: `rawJson.pages.contact.${lang}.contact`,
      component: 'group',
      label: 'Contact',
      description: 'Contact info',
      fields: [
        {
          name: 'name',
          component: 'text',
          label: 'Name',
          required: true,
        },
        {
          name: 'address',
          component: 'text',
          label: 'Address',
          required: true,
        },
        {
          name: 'telephone',
          component: 'text',
          label: 'Telephone Number',
          required: true,
        },
        {
          name: 'mail',
          component: 'text',
          label: 'Mail',
          required: true,
        },
      ],
    },
  ],
}

export const pageQuery = graphql`
  query {
    dataJson {
      pages {
        contact {
          de {
            site_title
            title
            messages {
              successMessage
              errorMessage
              msgErrorMessage
              mailErrorMessage
            }
            fields {
              mail {
                label
                placeholder
              }
              message {
                label
                placeholder
              }
              button
            }
            contact {
              name
              address
              telephone
              mail
            }
          }
        }
      }
      fileRelativePath
      rawJson
    }
  }
`
